import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EWayBill from './pages/EWayBill';
import Services from './pages/Services';
import Home from './pages/Home';
import PricingPlans from './pages/PricingPlans';
import Blogs from './pages/Blogs';
import ReachUs from './pages/ReachUs';
import TermsandCondition from './pages/TermsandCondition';
import Privacy from './pages/Privacy';
import Copilot from './pages/Co-pilot';
import CopilotIntro from './pages/CopilotIntro';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/pricing-plans" element={<PricingPlans />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/reach-us" element={<ReachUs />} />
          <Route path="/" element={<Home />} />
          <Route path="/eway" element={<EWayBill/>}/>
          {/* <Route path="/adv1" element={<Adv1 />} /> */}
          <Route path="/copilot-intro" element={<CopilotIntro />} />
          <Route path="/terms" element={<TermsandCondition />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/copilot-intro" element={<CopilotIntro />} />         
        </Routes>
      </div>
    </Router>
  );
}

export default App;
