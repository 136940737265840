import React, { useRef } from "react";
import html2pdf from "html2pdf.js";
import Barcode from "react-barcode";
import { QRCodeCanvas } from "qrcode.react";

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import apiService from "../services/api.service";

const EWayBill = () => {
  const [billData, setBillData] = useState({});
  const location = useLocation();
  const billRef = useRef();
  const getId = async () => {
    const queryParams = new URLSearchParams(location.search);
    let id = queryParams.get("_id");
    console.log({ id });

    let billData; // Declare billData using let instead of const

    if (id) {
      try {
        const queryParams = { _id: id };
        let response = await apiService.get("/user/eway", queryParams);
        console.log(response.ewayData);
        
        if(Object.keys(response.ewayData).length > 0){ // Assign the correct data directly
          billData = response.ewayData;
        }else{
          billData = {
            supplyType: " ",
            subSupplyType: " ",
            subSupplyDesc: " ",
            docType: " ",
            docNo: " ",
            docDate: " ",
            fromGstin: " ",
            fromTrdName: " ",
            fromAddr1: " ",
            fromAddr2: " ",
            fromPlace: " ",
            actFromStateCode: " ",
            fromPincode: " ",
            fromStateCode: " ",
            toGstin: " ",
            toTrdName: " ",
            toAddr1: " ",
            toAddr2: " ",
            toPlace: " ",
            toPincode: " ",
            actToStateCode: " ",
            toStateCode: " ",
            transactionType: " ",
            dispatchFromGSTIN: " ",
            dispatchFromTradeName: " ",
            shipToGSTIN: " ",
            shipToTradeName: " ",
            totalValue: "0",
            cgstValue: "0",
            sgstValue: "0",
            igstValue: "0",
            cessValue: "0",
            cessNonAdvolValue: "0",
            totInvValue: "0",
            transMode: " ",
            transDistance: " ",
            transporterName: "",
            transporterId: " ",
            transDocNo: " ",
            transDocDate: "",
            vehicleNo: " ",
            vehicleType: " ",
            itemList: [
              {
                productName: " ",
                productDesc: " ",
                hsnCode: "0",
                quantity: "0",
                qtyUnit: "BOX",
                taxableAmount: "0",
                sgstRate: 0,
                cgstRate: 0,
                igstRate: 0,
                cessRate: 0,
              },
            ],
            ewayBillNo: 0,
            generatedDate: " ",
            validUpto: " ",
            generatedBy: " ",
          };
        }
        
      } catch (error) {
        console.error("API Request Failed:", error);
        billData = {
          supplyType: " ",
          subSupplyType: " ",
          subSupplyDesc: " ",
          docType: " ",
          docNo: " ",
          docDate: " ",
          fromGstin: " ",
          fromTrdName: " ",
          fromAddr1: " ",
          fromAddr2: " ",
          fromPlace: " ",
          actFromStateCode: " ",
          fromPincode: " ",
          fromStateCode: " ",
          toGstin: " ",
          toTrdName: " ",
          toAddr1: " ",
          toAddr2: " ",
          toPlace: " ",
          toPincode: " ",
          actToStateCode: " ",
          toStateCode: " ",
          transactionType: " ",
          dispatchFromGSTIN: " ",
          dispatchFromTradeName: " ",
          shipToGSTIN: " ",
          shipToTradeName: " ",
          totalValue: "0",
          cgstValue: "0",
          sgstValue: "0",
          igstValue: "0",
          cessValue: "0",
          cessNonAdvolValue: "0",
          totInvValue: "0",
          transMode: " ",
          transDistance: " ",
          transporterName: "",
          transporterId: " ",
          transDocNo: " ",
          transDocDate: "",
          vehicleNo: " ",
          vehicleType: " ",
          itemList: [
            {
              productName: " ",
              productDesc: " ",
              hsnCode: "0",
              quantity: "0",
              qtyUnit: "BOX",
              taxableAmount: "0",
              sgstRate: 0,
              cgstRate: 0,
              igstRate: 0,
              cessRate: 0,
            },
          ],
          ewayBillNo: 0,
          generatedDate: " ",
          validUpto: " ",
          generatedBy: " ",
        };
      }
    } else {
      billData = {
        supplyType: " ",
        subSupplyType: " ",
        subSupplyDesc: " ",
        docType: " ",
        docNo: " ",
        docDate: " ",
        fromGstin: " ",
        fromTrdName: " ",
        fromAddr1: " ",
        fromAddr2: " ",
        fromPlace: " ",
        actFromStateCode: " ",
        fromPincode: " ",
        fromStateCode: " ",
        toGstin: " ",
        toTrdName: " ",
        toAddr1: " ",
        toAddr2: " ",
        toPlace: " ",
        toPincode: " ",
        actToStateCode: " ",
        toStateCode: " ",
        transactionType: " ",
        dispatchFromGSTIN: " ",
        dispatchFromTradeName: " ",
        shipToGSTIN: " ",
        shipToTradeName: " ",
        totalValue: "0",
        cgstValue: "0",
        sgstValue: "0",
        igstValue: "0",
        cessValue: "0",
        cessNonAdvolValue: "0",
        totInvValue: "0",
        transMode: "1",
        transDistance: "67",
        transporterName: "",
        transporterId: "05AAACG0904A1ZL",
        transDocNo: "12",
        transDocDate: "",
        vehicleNo: "APR3214",
        vehicleType: "R",
        itemList: [
          {
            productName: " ",
            productDesc: " ",
            hsnCode: "0",
            quantity: "0",
            qtyUnit: "BOX",
            taxableAmount: "0",
            sgstRate: 0,
            cgstRate: 0,
            igstRate: 0,
            cessRate: 0,
          },
        ],
        ewayBillNo: 0,
        generatedDate: " ",
        validUpto: " ",
        generatedBy: " ",
      };
    }

    setBillData(billData); // Set the bill data at the end
  };
  const handlePrint = () => {
    if (window.PrintChannel) {
      // Running in Flutter WebView
      window.PrintChannel.postMessage('print');
    } else {
      // Running in a browser
      window.print();
    }
  }
  useEffect(() => {
    console.log("EWayBill component mounted");

    // Check initially when component mounts
    getId();
  }, []);
  //checkBillData();
  const qrCodeValue = `eWay Bill No: ${billData.ewayBillNo || "N/A"}, Validity Time: ${billData.validity_time || "N/A"}, Generated Time: ${billData.generatedDate || "N/A"}`;

  return (
// <div className="w-210mm h-297mm mx-auto max-w-screen-lg">
    <div ref={billRef} className="w-[80%] mx-auto my-5 p-5 bg-white shadow-lg rounded-lg border">
    <div className="flex items-center justify-between mb-13 h-15">
    <h1 className="font-bold mt-4 mb-2 text-lg sm:text-xl md:text-[14px] lg:text-3xl">E-Way Bill</h1>
    <div className="flex flex-col items-center">
      <QRCodeCanvas
        value={qrCodeValue}
        style={{
          width: '10rem', // Increased from 3rem to 4rem
          height: '10rem', // Increased from 3rem to 4rem
          margin: '1rem', // Increased margin slightly
          padding: '0.75rem' // Increased padding for a balanced look
        }}
      />
    </div>
  </div>
<div className="border border-gray-300 mb-4 my-2"> 
<h1 className="bg-[#736fb3] text-black p-1 text-sm sm:text-base md:text-[14px] lg:text-xl text-left rounded-md font-bold">
  1. E-WAY BILL Details
</h1>

          <div className="p-1 border flex justify-between text-[6px] sm:text-[10px] md:text-[14px] lg:text-[16px] text-left">
            <p className="flex-1">
              <span className="font-normal">eWay Bill No:</span>{" "}
              <span className="font-bold">{billData.ewayBillNo || "N/A"}</span>{" "}
            </p>
            <p className="flex-1">
              <span className="font-normal">Generated Date:</span>{" "}
              <span className="font-bold">
                {billData.generatedDate || "N/A"}
              </span>
            </p>
            <div>
            <p className="flex-1 text-right ">
              <span className="font-normal">Generated By:</span>{" "}
              <span className="font-bold">{billData.generatedBy || "N/A"}</span>
            </p>
            <p className="flex-1 text-right">
            <span className="font-normal">Valid Upto:</span>{" "}
      <span className="font-bold">{billData.validUpto || "N/A"}</span>
            </p>
            </div>
          </div>

          <div className="p-1 border flex justify-between text-[8px] sm:text-[10px] md:text-[14px] lg:text-[16px]">
            <p>
              <span className="font-normal">Mode:</span>{" "}
              <span className="font-bold">{billData.transMode || "N/A"}</span>
            </p>
            <p className="text-left">
              <span className="font-normal">Approx Distance:</span>{" "}
              <span className="font-bold">
                {billData.transDistance || "N/A"}
              </span>
            </p>
          </div>

          <div className="p-1 border flex justify-between text-[7px] sm:text-[10px] md:text-[14px] lg:text-[16px] text-left">
            <p className="flex-1">
              <span className="font-normal">Type:</span>
              {billData.supplyType} - {billData.subSupplyType}
              <span className="font-bold">
                {billData.transactionTypeDesc || " "}
              </span>
            </p>
            <p className="flex-1">
              <span className="font-normal">Document Details:</span>{" "}
              <span className="font-bold">
                {billData.docType} - {billData.docNo} - {billData.docDate}
              </span>
            </p>
            <p className="flex-1 text-right">
              <span className="font-normal">Transaction Type:</span>{" "}
              <span className="font-bold">
                {billData.transactionType || " "}
              </span>
            </p>
          </div>
        </div>
          <h4 className="bg-[#736fb3] text-black sm:text-sm md:text-[14px] lg:text-[16px] text-[11px] p-2 mb-1 text-left rounded-md font-bold my-2">
            2. Address Details
          </h4>
          <div className="flex justify-between mb-4 text-[7px] sm:text-[10px] md:text-[12px]">
          <div className="w-1/2 border border-gray-300 bg-white ml-2 mr-1 my-1">
            <h2 className="bg-[#736fb3] text-left text-black p-1 sm:text-sm md:text-[14px] lg:text-[16px] text-[11px] rounded-md font-bold">
              From
            </h2>
            <div className="sm:p-1 md:p-2 sm:text-sm md:text-[14px] lg:text-[16px] text-[8px] text-left">
              <p>
                <span className="font-normal">GSTIN: </span>{" "}
                <span className="font-bold">{billData.fromGstin || " "}</span>
              </p>
              <p>
                <span className="font-normal">{billData.fromTrdName || " "}</span>
              </p>
              <br />

              <p className="font-normal">:: Dispatch From ::</p>
              <p>
                <span className="font-normal">
                  {billData.fromAddr1}, <br />
                  {billData.fromAddr2}, {billData.fromPlace}, {billData.fromPincode}
                </span>
              </p>
            </div>
          </div>

          <div className="w-1/2 border border-gray-300 bg-white mr-2 ml-1 my-1">
            <h2 className="bg-[#736fb3] text-black sm:text-sm md:text-[14px] lg:text-[16px] text-[11px] p-1 text-left rounded-md font-bold">
              To
            </h2>
            <div className="sm:p-2 sm:text-sm md:text-[14px] lg:text-[16px] text-[8px] text-left">
              <p>
                <span className="font-normal">GSTIN: </span>{" "}
                <span className="font-bold">{billData.toGstin || " "}</span>
              </p>
              <p>
                <span className="font-normal">{billData.toTrdName || " "}</span>
              </p>
              <br />

              <p className="font-normal">:: Shipped From ::</p>
              <p>
                <span className="font-normal">
                  {billData.toAddr1}, <br />
                  {billData.toAddr2}, {billData.toPlace}, {billData.toPincode}
                </span>
              </p>
            </div>
          </div>
        </div>


        <div className="border border-gray-300 pt-0 mb-5 bg-white my-2">
          <h2 className="bg-[#736fb3] text-left text-black font-semibold p-2 sm:text-sm md:text-[14px] lg:text-[16px] text-[11px] rounded-md font-bold">
            3. Goods Details
          </h2>
          <table className="w-full border-collapse mt-2 text-[6px] sm:text-[10px] md:text-[14px] lg:text-[16px]">
  <thead>
    <tr>
      <th className="border border-gray-300 p-1">HSN</th>
      <th className="border border-gray-300 p-1">Product & Desc.</th>
      <th className="border border-gray-300 p-1">Quantity</th>
      <th className="border border-gray-300 p-1">Taxable Amount</th>
      <th className="border border-gray-300 p-1">Tax Rate (C+S+I+Cess)</th>
    </tr>
  </thead>
  <tbody>
    {billData.itemList && billData.itemList.length > 0 ? (
      billData.itemList.map((item, index) => (
        <tr key={index}>
          <td className="border border-gray-300 p-1 text-center break-words sm:text-[10px] md:text-[13px] lg:text-[16px]">
            {item.hsnCode || "N/A"}
          </td>
          <td className="border border-gray-300 p-1 text-center break-words sm:text-[10px] md:text-[13px] lg:text-[16px]">
            {item.productName || "N/A"} & {item.productDesc || "N/A"}
          </td>
          <td className="border border-gray-300 p-1 text-center sm:text-[10px] md:text-[13px] lg:text-[16px]">
            {item.quantity || "N/A"} {item.qtyUnit || "N/A"}
          </td>
          <td className="border border-gray-300 p-1 text-center sm:text-[10px] md:text-[13px] lg:text-[16px]">
            {item.taxableAmount || "N/A"}
          </td>
          <td className="border border-gray-300 p-1 text-center break-words sm:text-[10px] md:text-[13px] lg:text-[16px]">
            {item.sgstRate.toFixed(2) || "NE"} + {item.cgstRate.toFixed(2) || "NE"} +{" "}
            {item.igstRate != 0 ? item.igstRate.toFixed(2) : "NE"} + {item.cessRate.toFixed(2) || "0.00"}
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="5" className="text-center">
          No items available
        </td>
      </tr>
    )}
  </tbody>
</table>


<table className="w-full border-collapse mt-4 text-[5px] sm:text-sm md:text-[13px] lg:text-[16px] text-[11px] rounded-md font-bold">
  <thead>
    <tr>
      <th className="border border-gray-300 p-1">Tot. Taxable Amt</th>
      <th className="border border-gray-300 p-1">CGST Amt</th>
      <th className="border border-gray-300 p-1">SGST Amt</th>
      <th className="border border-gray-300 p-1">IGST Amt</th>
      <th className="border border-gray-300 p-1">CESS Amt</th>
      <th className="border border-gray-300 p-1">CESS Non.Advol Amt</th>
      <th className="border border-gray-300 p-1">Other Amt</th>
      <th className="border border-gray-300 p-1">Total Inv. Amount</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td className="border border-gray-300 p-1 text-center sm:text-sm md:text-[13px] lg:text-[16px]">
        <div className="p-1 border border-gray-300 bg-white rounded break-words">
          {billData.totalValue || "0.00"}
        </div>
      </td>
      <td className="border border-gray-300 p-1 text-center">
        <div className="p-1 border border-gray-300 bg-white rounded break-words">
          {billData.cgstValue || "0.00"}
        </div>
      </td>
      <td className="border border-gray-300 p-1 text-center">
        <div className="p-1 border border-gray-300 bg-white rounded break-words">
          {billData.sgstValue || "0.00"}
        </div>
      </td>
      <td className="border border-gray-300 p-1 text-center">
        <div className="p-1 border border-gray-300 bg-white rounded break-words">
          {billData.igstValue || "0.00"}
        </div>
      </td>
      <td className="border border-gray-300 p-1 text-center">
        <div className="p-1 border border-gray-300 bg-white rounded break-words">
          {billData.cessValue || "0.00"}
        </div>
      </td>
      <td className="border border-gray-300 p-1 text-center">
        <div className="p-1 border border-gray-300 bg-white rounded break-words">
          {billData.cessNonAdvolValue || "0.00"}
        </div>
      </td>
      <td className="border border-gray-300 p-1 text-center">
        <div className="p-1 border border-gray-300 bg-white rounded break-words">
          {billData.otherValue || "0.00"}
        </div>
      </td>
      <td className="border border-gray-300 p-1 text-center">
        <div className="p-1 border border-gray-300 bg-white rounded break-words">
          {billData.totalValue || "0.00"}
        </div>
      </td>
    </tr>
  </tbody>
</table>

        </div>

        <div className="border border-gray-300  bg-white text-[9px] sm:text-sm md:text-[14px] lg:text-[16px] my-2">
          <h2 className="bg-[#736fb3] text-left text-black font-semibold p-1 text-sm sm:text-base md:text-lg text-left rounded-md font-bold">
            4. Transportation Details
          </h2>
          <div className="flex flex-row justify-between p-2 space-y-1 text-[9px] text-sm sm:text-base sm:text-sm md:text-[14px] lg:text-[16px]">
            <div className="flex items-center text-[9px] sm:text-sm md:text-[14px] lg:text-[16px]">
              <span className="font-normal mr-2">Transporter ID & Name:</span>
              <span className="font-normal">
                {billData.transporterName || " "},{" "}
                {billData.transporterId || " "}
              </span>
            </div>
            <div className="flex items-center sm:text-sm text-[9px] text-sm sm:text-base sm:text-sm md:text-[14px] lg:text-[16px]">
              <span className="font-normal mr-2">
                Transporter Doc. No & Date:
              </span>
              <span className="font-normal">
                {billData.transDocNo || " "}&{billData.docDate || " "}
              </span>
            </div>
          </div>
        </div>

        <div className="border border-gray-300 bg-white mb-5 my-2">
          <h2 className="bg-[#736fb3] text-left text-black font-semibold p-2 text-sm sm:text-base md:text-[14px] lg:text-[16px] text-left rounded-md font-bold text-[9px]">
            5. Vehicle Details
          </h2>
          <table className="w-full border-collapse mt-2 text-[6px] sm:text-xs md:text-[14px] lg:text-[16px]">
  <thead>
    <tr>
      <th className="border border-gray-300 px-1 py-1">Mode</th>
      <th className="border border-gray-300 px-1 py-1">
        Vehicle / Trans Doc No & Dt.
      </th>
      <th className="border border-gray-300 px-1 py-1">From</th>
      <th className="border border-gray-300 px-1 py-1">Entered Date</th>
      <th className="border border-gray-300 px-1 py-1">Entered By</th>
      <th className="border border-gray-300 px-1 py-1">CEWB No. (if any)</th>
      <th className="border border-gray-300 px-1 py-1">Multi Veh. Info (if any)</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td className="border border-gray-300 px-1 py-1 text-center break-words sm:text-sm md:text-[13px] lg:text-[16px]">
        {billData.transMode || "Not Provided"}
      </td>
      <td className="border border-gray-300 px-1 py-1 text-center break-words sm:text-sm md:text-[13px] lg:text-[16px]">
        {billData.vehicleNo || "Not Provided"} &{" "}
        {billData.generatedDate ? billData.generatedDate.split(" ")[0] : " "}
      </td>
      <td className="border border-gray-300 px-1 py-1 text-center break-words sm:text-sm md:text-[13px] lg:text-[16px]">
        {billData.fromPlace || "Not Provided"}
      </td>
      <td className="border border-gray-300 px-1 py-1 text-center break-words sm:text-sm md:text-[13px] lg:text-[16px]">
        {billData.generatedDate || "Not Provided"}
      </td>
      <td className="border border-gray-300 px-1 py-1 text-center break-words sm:text-sm md:text-[13px] lg:text-[16px]">
        {billData.generatedBy || "Not Provided"}
      </td>
      <td className="border border-gray-300 px-1 py-1 text-center break-words sm:text-sm md:text-[13px] lg:text-[16px]">
        {"-" || "Not Provided"}
      </td>
      <td className="border border-gray-300 px-1 py-1 text-center break-words sm:text-sm md:text-[13px] lg:text-[16px]">
        {"-" || "Not Provided"}
      </td>
    </tr>
  </tbody>
</table>

          <div className="flex justify-center items-center">
            <Barcode
              value={
                billData.ewayBillNo ? billData.ewayBillNo.toString() : "N/A"
              }
              className="w-48 h-24"
            />
          </div>
          <div className="text-left text-[10px] sm:text-sm md:text-[14px] lg:text-[16px]">
          Note*: If there is any discrepancy in information please try again
          after sometime.</div>
        </div>
        <div className="flex justify-center my-2">
        <button
          onClick={handlePrint}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Print
        </button>
      </div>
      </div>
  );
};

export default EWayBill;
