import React from 'react'

const ReachUs = () => {
  return (
    <div>
      reach us
    </div>
  )
}

export default ReachUs
