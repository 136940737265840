class ApiService {
    constructor() {
      this.baseUrl = 'https://fyntl.sangrahinnovations.com'; // Set your base URL here
      //this.baseUrl = 'http://localhost:3006'
      this.defaultHeaders = {
        'Content-Type': 'application/json',
        // Add any additional headers like authorization here
      };
    }
  
    // Helper method to make API calls
    async makeRequest(route, method = 'GET', body = null, headers = {}) {
      const url = `${this.baseUrl}${route}`;
      const config = {
        method,
        headers: { ...this.defaultHeaders, ...headers }, // Merge default headers with custom headers
      };
  
      if (body) {
        config.body = JSON.stringify(body);
      }
  
      try {
        const response = await fetch(url, config);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        return await response.json(); // Parse JSON response
      } catch (error) {
        console.error('API Request Failed:', error);
        throw error;
      }
    }
  
    // Example API call: GET request
    async get(route, queryParams = {}, headers = {}) {
        const urlParams = new URLSearchParams(queryParams).toString();
        const fullRoute = `${route}?${urlParams}`;
        return this.makeRequest(fullRoute, 'GET', null, headers);
      }
  
    // Example API call: POST request
    async post(route, body, headers = {}) {
      return this.makeRequest(route, 'POST', body, headers);
    }
  
    // Example API call: PUT request
    async put(route, body, headers = {}) {
      return this.makeRequest(route, 'PUT', body, headers);
    }
  
    // Example API call: DELETE request
    async delete(route, headers = {}) {
      return this.makeRequest(route, 'DELETE', null, headers);
    }
  }
  
  // Create a singleton instance of the API service
  const apiService = new ApiService();
  export default apiService;
  