import React from 'react'

const PricingPlans = () => {
  return (
    <div>
      pricing plans
    </div>
  )
}

export default PricingPlans
